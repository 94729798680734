import ContactForm from 'components/ContactForm'

export default function Calendar() {
	return (
		<div className="container special">
			<h1>Termin vereinbaren</h1>
			<p>
				Möchten Sie gerne einen Termin vereinbaren? Sie erreichen mich mit dem folgenden
				Kontaktformular oder reservieren Sie sich gleich direkt im Kalender einen passenden
				Termin.
			</p>
			<div className="row">
				<div className="col-12">
					<ContactForm />
				</div>
			</div>
		</div>
	)
}
