import Footer from '../components/Footer'
import NavigationNew from '../components/Navigation2'

export default function DataProtection() {
	return (
		<>
			<NavigationNew />

			<div id="page-help" className="navigation">
				<div className="background-color-base">
					<div className="container normal">
						<h1>Datenschutzerklärung (DSE)</h1>
						<p>Version vom 11.10.2023</p>
						<p>Ich, Julija Hauser</p>
						<p>Praxisname: RJ-Homeopathy</p>
						<p>Dufourstrasse 106, 8008 Zürich und Bösch 41, 6331 Hünenberg</p>
						<p>
							bestätige hiermit, mich an die folgenden Regeln gemäss Datenschutzgesetz
							und Datenschutzverordnung zu halten.
						</p>
						<p>
							Zweck dieser Datenschutzerklärung ist es, Sie als Patienten/Patientin,
							über unseren/meinen Umgang mit Ihren persönlichen und sensiblen Daten,
							die in unserer Praxis gesammelt werden, zu informieren.
						</p>
						<p>
							Es werden nur solche Daten bearbeitet, die mit der
							alternativmedizinischen Behandlung im Zusammenhang stehen. oder die für
							eine Information über allfällige Angebote relevant sind. Es handelt sich
							dabei ausschliesslich um Daten, die ich mit ihrem Einverständnis
							aufgenommen habe, die Sie mir haben zukommen lassen oder die in
							öffentlich zugänglichen Verzeichnissen (Telefonbuch etc.) zu finden
							sind. Dies können allgemeine Daten zu Ihrer Person (Name, Anschrift,
							Telefonnummer usw.), Angaben zu Ihrer Gesundheit/zum Krankheitsverlauf,
							bereits gestellte Diagnosen und gegebenenfalls weitere Daten sein,
							welche Sie uns aufgrund der Behandlung übermittelt haben
							(Patientendossiers).
						</p>
						<p>
							Gesammelt, gespeichert und bearbeitet werden diese Daten ausschliesslich
							in Ihrem Patient*innendossier, resp. in dem für die Administration
							notwendigen Dossier, insbesondere in der für die Fakturierung gemäss
							Tarif 590 notwendigen App. In diesen beiden Aufewahrungsorten sind
							sämtliche an Ihren Daten vorgenommenen Bearbeitungsschritte inklusive der
							verantwortlichen Personen nachvollziehbar.
						</p>
						<p>
							Ihre personenbezogenen Daten gebe ich ausschliesslich an Personen oder
							Bereiche unsere Praxis weiter, die diese zur Erfüllung der vertraglichen
							und gesetzlichen Pflichten benötigen. Diese sind über die geltenden
							Datenschutzregeln informiert und verpflichtet, diese einzuhalten.
						</p>
						<p>
							Weiteren Personen oder Institutionen (Versicherer etc.) werden Ihre
							Daten oder Teile davon ausschliesslich mit Ihrem expliziten
							Einverständnis zugänglich gemacht. Als Ausnahmen gelten richterliche
							Anordnungen oder die Durchsetzung berechtigter Ansprüche seitens der
							Praxis.
						</p>
						<p>
							Soweit Ihre Daten physisch gesammelt und bearbeitet werden, werden diese
							in einem abschliessbaren, unbefugten Drittpersonen nicht zugänglichen
							Raum oder Schrank aufbewahrt. Elektronisch gesammelte Daten werden
							gesichert aufbewahrt (Firewall, Passwort etc.).
						</p>
						<p>
							Wo nicht kantonale oder weitere gesetzliche Regelungen anderes
							bestimmen, werden Ihre von uns/mir gesammelten Daten zwanzig Jahre nach
							Ihrer letzten Konsultation in unserer Praxis gelöscht.
						</p>
						<p>
							Die schriftliche Kommunikation per E-Mail zwischen meiner Praxis und
							Ihnen, erfolgt mit einem üblichen Mail-Verschlüsselungsprogramm. Eine
							unverschlüsselte Kommunikation per E-Mail wird nur mit Ihrem
							schriftlichen Einverständnis stattfinden.
						</p>
						<p>
							Als gegenwärtige oder ehemalige Patient*in können Sie von mir jederzeit
							ein Doppel aller Ihrer von mir gesammelten Daten in einem gängigen
							elektronischen Format verlangen. Nur physisch vorhandene Daten werden zu
							diesem Zweck gescannt und Ihnen als PDF oder in Papierform zugestellt.
							Die Daten werden Ihnen in der Regel kostenlos und innert maximal 30
							Tagen übergeben.
						</p>
						<p>
							Julia Hauser
							<br />
							Bösch 41
							<br />
							6331 Hünenberg
							<br />
							<a href="mailto:julija.hauser@rj-homeopathy.ch">
								julija.hauser@rj-homeopathy.ch
							</a>
						</p>
						<hr />
						<p>
							Datenschutzerklärung für Naturheilpraktiker*innen, Version Juli 2023
							<br />
							<a href="https://www.oda-am.ch" target="_blank">
								www.oda-am.ch
							</a>
						</p>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)
}
