import Button from 'components/Button/Button'
import { useState } from 'react'

import { RenderIf } from 'components/RenderIf'
import emailjs from '@emailjs/browser'

const serviceId: string = 'service_2ej7l7m'
const templateId: string = 'template_0qn05i4'
const publicKey: string = 'dfKXk4Hxv3ZbANVr8'

type TemplateParams = {
	name: string
	phone: string
	message: string
	email: string
}

type FormItem = {
	id: keyof Form
	value: string
	type: 'text' | 'email'
	className: string
	placeholder: string
	rows?: number
}

type Form = {
	name: FormItem
	phone: FormItem
	message: FormItem
	email: FormItem
}

const initialForm: Form = {
	name: {
		id: 'name',
		value: '',
		type: 'text',
		className: 'form-control',
		placeholder: 'Vorname, Name',
	},
	email: {
		id: 'email',
		value: '',
		type: 'email',
		className: 'form-control',
		placeholder: 'E-Mail',
	},
	phone: {
		id: 'phone',
		value: '',
		type: 'text',
		className: 'form-control',
		placeholder: 'Telefon',
	},
	message: {
		id: 'message',
		value: '',
		type: 'text',
		className: 'form-control',
		placeholder: 'Mitteilung',
		rows: 4,
	},
}

type Status = 'loading' | 'done' | 'todo' | 'error'

export default function ContactForm() {
	const [form, setForm] = useState<Form>(initialForm)
	const [status, setStatus] = useState<Status>('todo')

	function renderInputField(field: FormItem): JSX.Element {
		if (field.rows) {
			return (
				<textarea
					key={field.id}
					rows={field.rows}
					className={field.className}
					placeholder={field.placeholder}
					onChange={(e) => changeForm(field.id, e.target.value)}
				/>
			)
		}
		return (
			<input
				key={field.id}
				type={field.type}
				className={field.className}
				value={field.value}
				placeholder={field.placeholder}
				onChange={(e) => changeForm(field.id, e.target.value)}
			/>
		)
	}

	function changeForm(id: keyof Form, value: string) {
		form[id].value = value
		setForm({ ...form })
	}

	function sendEmail(e: any) {
		e.preventDefault()
		const newForm: Form = validateForm(form)
		setForm(newForm)
		if (!isFormValid(newForm)) return
		setStatus('loading')

		const templateParams: TemplateParams = {
			name: form.name.value,
			phone: form.phone.value,
			message: form.message.value,
			email: form.email.value,
		}

		emailjs.send(serviceId, templateId, templateParams, publicKey).then(
			function (response) {
				console.info('SUCCESS!', response.status, response.text)
				setStatus('done')
			},
			function (error) {
				console.error('FAILED...', error)
				setStatus('error')
			}
		)
	}

	return (
		<>
			<p className="bold">Kontaktformular</p>
			<RenderIf condition={status === 'todo'}>
				<div className="d-flex flex-column" style={{ gap: '10px' }}>
					{Object.values(form).map((e: FormItem) => {
						return renderInputField(e)
					})}
					<Button label="Senden" onClick={sendEmail} />
				</div>
			</RenderIf>
			<RenderIf condition={status === 'done'}>
				<div>
					Vielen Danke für Ihre Nachricht.{' '}
					<span
						style={{ cursor: 'pointer', color: '#ac9957' }}
						onClick={() => setStatus('todo')}
					>
						Neue Nachricht versenden.
					</span>
				</div>
			</RenderIf>
			<RenderIf condition={status === 'error'}>
				<div>
					Es ist ein Fehler passiert.{' '}
					<span
						style={{ cursor: 'pointer', color: '#ac9957' }}
						onClick={() => setStatus('todo')}
					>
						Nochmal versuchen.
					</span>
				</div>
			</RenderIf>
			<RenderIf condition={status === 'loading'}>
				<div className="d-flex align-items-center" style={{ margin: '10px', gap: '10px' }}>
					<div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
					<strong>Ihre Nachricht wird versendet …</strong>
				</div>
			</RenderIf>
		</>
	)
}

function validateForm(inputForm: Form): Form {
	const form: Form = { ...inputForm }
	for (const value of Object.values(form)) {
		if (!value.value) {
			value.className = 'form-control is-invalid'
		} else {
			value.className = 'form-control'
		}
	}
	return form
}

function isFormValid(form: Form): boolean {
	for (const value of Object.values(form)) {
		if (value.className === 'form-control is-invalid') return false
	}
	return true
}
